
.moverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto; /* Allow scrolling */
  }
  
  /* Popup container */
  .mpopup {
    background-color: #f1f6ff;
    padding: 20px;
    border-radius: 16px;
    width: 90%;
    height: 85vh;
    min-height: 600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1001;
  }
  
  /* Close button */
  .mclose-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }

  .mbeta-content-container {
    flex-direction: row;
    justify-content: flex-start;
    height: 100%
  }

  .mbeta-header-cont {
    width: 80%;
    margin-left: 7.5%;
    text-align: left;
    margin-top: 12%;
  }

  .mbeta-header-text {
    font-size: 8vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 700;
  }

  .mbeta-desc-cont {
    width: 80%;
    margin-left: 7.5%;
    text-align: left;
    margin-top: 5.5%;
  }

  .mbeta-desc-text {
    font-size: 5vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 400;
  }


  .mbeta-namecity-cont {
    width: 80%;
    margin-left: 7.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10%;
    height: 8.1%;
  }

  .mbeta-name-form {
    width: 48%;
    text-align: left;
    height: 100%;
    border-radius: 9px;
    font-size: 4.5vw;
    padding-left: 12px;
    background-color: #dee8fc;
    border-width: 1px;
  }

  .mbeta-email-cont {
    width: 80%;
    margin-left: 7.5%;
    margin-top: 5%;
    height: 8.1%;
  }

  .mbeta-email-form {
    width: 100%;
    text-align: left;
    height: 100%;
    border-radius: 9px;
    font-size: 4.5vw;
    padding-left: 12px;
    background-color: #dee8fc;
    border-width: 1px;
  }

  .mbeta-disc-cont {
    width: 80%;
    margin-left: 7.5%;
    margin-top: 2%;
  }

  .mbeta-disc-text {
    font-size: 3.2vw;
    font-family: 'Raleway';
    color: #7f7f7f;
    font-weight: 400;
  }

  .mbeta-button-cont {
    width: 40%;
    margin-left: 7.5%;
    margin-top: 7%;
    height: 8.1%;
  }

  .mbeta-message-cont {
    width: 80%;
    margin-left: 7.5%;
    margin-top: 2.5%;
  }

  .mbeta-message-text {
    font-size: 3.4vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 400;
  }


  .mbeta-subheader-cont {
    width: 80%;
    margin-left: 7.5%;
    text-align: left;
    margin-top: 20%;
  }

  .mbeta-subheader-text {
    font-size: 8vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 700;
  }

  .mbeta-subdesc-cont {
    width: 85%;
    margin-left: 7.5%;
    text-align: left;
    margin-top: 8%;
  }

  .mbeta-subdesc-text {
    font-size: 5vw;
    font-family: 'Raleway';
    color: black;
    font-weight: 400;
  }

  .mbeta-check-cont {
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 5svh;
    width: 100%;
  }

  .mbeta-check {
    height: min(27.5svh, 60vw);
    aspect-ratio: 1;
  }